import { useEffect, useState } from "react"
import {
  // ChakraProvider,
  Box,
  Grid,
  // theme,
  Card,
  CardBody,
  Flex,
  Image,
  Stack,
  Heading,
  Text,
  Skeleton,
  IconButton,
  HStack,
  Badge,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  keyframes,
  LightMode,
  // useColorModeValue,
} from "@chakra-ui/react"
// import Navbar from "./components/Navbar"
import NavbarHome from "./components/NavbarHome";
import ReactGA from 'react-ga4';
import { InfoIcon } from "@chakra-ui/icons";
import LogoDescription from "./components/LogoDescription";

export const HomePage = () => {
  const [isImageLoaded, setImageLoaded] = useState({
    homepage_1: false,
    homepage_2: false,
    homepage_3: false,
    homepage_4: false
  });

  const handleImageLoad = (imageName: keyof typeof isImageLoaded) => {
    setImageLoaded(prevState => ({ ...prevState, [imageName]: true }));
  };
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "HomePage" });
  }, []);

  // Modal controls
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();
  const { isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4 } = useDisclosure();
  
  // Pulsating animation keyframes
  const pulseAnimation = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  `;

  const pulse = `${pulseAnimation} 2s infinite`; // Animation applied over 2s and loops infinitely

  return (
    // <ChakraProvider theme={theme}>
    <LightMode>
      <Box>
      <NavbarHome />

      <Box
        position="relative"
        backgroundImage="background1.png" // Path to your background image
        backgroundSize="cover"
        backgroundPosition="center"
  //       _before={{
  //         content: '""',
  //         position: "absolute",
  //         bgSize: "cover",
  //         top: 0,
  //         left: 0,
  //         right: 0,
  //         bottom: 0,
  //         // backgroundColor: "rgba(0, 0, 0, 0.5)", // Overlay color with transparency
  //         zIndex: -1,
  //       }}
  //       zIndex={-2}
  //       minH="93vh"
        border={"2px solid white"}
  //       backgroundRepeat="no-repeat"
  //       m={0}  // Ensure no margin is applied
  // p={0}  // Ensure no padding is applied
      >

      <LogoDescription />

      <Box zIndex={1}>
        <Flex
          minH={{ base: "80vh", md: "85vh" }}
          align="center"
          justify="center"
          ml={{ base: 4, md: 10 }}
          mr={{ base: 4, md: 10 }}
          p={4}
          mt={{ base: 40, md: 10 }}
        >
          <Stack spacing={8} align="center">
            <Heading as="h1" size="xl" textAlign="center">
              Welcome to JudgeAI
            </Heading>

            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={10}>
            

            <Box animation={pulse} borderRadius="lg" border="1px solid transparent">
              <Card maxW='xs' h="full">
                <Badge color={"red.900"} backgroundColor={"red.100"} variant={"subtle"} position="absolute" top="4" left="4" fontSize={"1em"}>
                  Main Feature
                </Badge>
                <CardBody>
                  <Skeleton isLoaded={isImageLoaded.homepage_1}>
                    <Image
                      src='homepage_1.webp'
                      alt='automated judicial processing'
                      borderRadius='lg'
                      onLoad={() => handleImageLoad('homepage_1')}
                    />
                  </Skeleton>
                  <Stack mt='6' spacing='3'>
                    <HStack spacing='4'>
                      <Button variant={"outline"} as={'a'} href={"judgement"} flex="6">
                        Mock Trial
                      </Button>
                      <IconButton
                        aria-label='Info'
                        icon={<InfoIcon />}
                        variant={"outline"}
                        flex="1"
                        onClick={onOpen1}
                      />
                    </HStack>
                  </Stack>
                </CardBody>
              </Card>
            </Box>

            {/* Modal for Card 1 */}
            <Modal isOpen={isOpen1} onClose={onClose1}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader textAlign="center">Judicial Perspective</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Image src="info_1.png" alt="Codified Law" borderRadius='lg' pl={4} pr={4} />
                  <Text mt={4}>
                    This feature provides a second opinion on economic disputes, which can be useful for judges, legal practitioners, and companies involved in litigation.
                    <br />
                    JudgeAI automates the judicial process from case registration to final judgment. It processes documents, analyzes evidence, and generates judicial decisions and comprehensive reports.
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <Button variant="ghost" onClick={onClose1}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Card 2 */}
            <Card maxW='xs' h="full">
              <CardBody>
                <Skeleton isLoaded={isImageLoaded.homepage_2}>
                  <Image
                    src='homepage_2.webp'
                    alt='judicial processing'
                    borderRadius='lg'
                    onLoad={() => handleImageLoad('homepage_2')}
                  />
                </Skeleton>
                <Stack mt='6' spacing='3'>
                  <HStack spacing='4'>
                    <Button variant={"outline"} as={'a'} href={"perspective"} flex="6">
                      Judicial Perspective
                    </Button>
                    <IconButton
                      aria-label='Info'
                      icon={<InfoIcon />}
                      variant={"outline"}
                      flex="1"
                      onClick={onOpen2}
                    />
                  </HStack>
                </Stack>
              </CardBody>
            </Card>

            {/* Modal for Card 2 */}
            <Modal isOpen={isOpen2} onClose={onClose2}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader textAlign="center">Judicial Perspective</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Image src="info_2.png" alt="Codified Law" borderRadius='lg' />
                  <Text mt={4}>
                    JudgeAI evaluates the submitted documents to predict the success of a case, providing strategic recommendations and suggesting potential evidence to strengthen the case.
                    <br />
                    It assesses the strength and relevance of the provided evidence, determines the likely outcomes of the case, suggests tactics to improve a party’s position, and identifies missing evidence that could be crucial.
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <Button variant="ghost" onClick={onClose2}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Card 3 */}
            <Card maxW='xs' h="full">
              <CardBody>
                <Skeleton isLoaded={isImageLoaded.homepage_3}>
                  <Image
                    src='homepage_3.webp'
                    alt='terms of settlement'
                    borderRadius='lg'
                    onLoad={() => handleImageLoad('homepage_3')}
                  />
                </Skeleton>
                <Stack mt='6' spacing='3'>
                  <HStack spacing='4'>
                    <Button
                      variant={"outline"}
                      as={'a'}
                      href={"settlement"}
                      flex="6"
                      whiteSpace="normal"
                      textOverflow="ellipsis"
                      textAlign="center"
                    >
                      Terms of Settlement Agreement
                    </Button>
                    <IconButton
                      aria-label='Info'
                      icon={<InfoIcon />}
                      variant={"outline"}
                      flex="1"
                      onClick={onOpen3}
                    />
                  </HStack>
                </Stack>
              </CardBody>
            </Card>

            {/* Modal for Card 3 */}
            <Modal isOpen={isOpen3} onClose={onClose3}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader textAlign="center">Terms of Settlement Agreement</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Image src="info_3.png" alt="Codified Law" borderRadius='lg' />
                  <Text mt={4}>
                    The platform calculates equitable settlement terms based on a thorough analysis of both the claim statement and the defendant’s position.
                    <br />
                    It conducts a detailed analysis of the parties' positions, calculates a fair settlement amount, proposes conditions that can satisfy both parties, and facilitates reaching an amicable agreement.
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <Button variant="ghost" onClick={onClose3}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Card 4 */}
            <Card maxW='xs' h="full">
              <CardBody>
                <Skeleton isLoaded={isImageLoaded.homepage_4}>
                  <Image
                    src='homepage_4.webp'
                    alt='codified law'
                    borderRadius='lg'
                    onLoad={() => handleImageLoad('homepage_4')}
                  />
                </Skeleton>
                <Stack mt='6' spacing='3'>
                  <HStack spacing='4'>
                    <Button
                      variant={"outline"}
                      as={'a'}
                      href={"codified_law"}
                      flex="6"
                      whiteSpace="normal"
                      textOverflow="ellipsis"
                      textAlign="center"
                    >
                      Mock Trial <br /> (Codified Law)
                    </Button>
                    <IconButton
                      aria-label='Info'
                      icon={<InfoIcon />}
                      variant={"outline"}
                      flex="1"
                      onClick={onOpen4}
                    />
                  </HStack>
                </Stack>
              </CardBody>
            </Card>

            {/* Modal for Card 4 */}
            <Modal isOpen={isOpen4} onClose={onClose4}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader textAlign="center">Mock Trial (Codified Law)</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Image src="flags/ae.svg" alt="Codified Law" borderRadius='lg' />
                  <Text mt={4}>
                    This feature applies the principles of the UAE Civil Code to resolve disputes, ensuring accurate legal adjudication.
                    <br />
                    It analyzes the case considering the specific norms of the UAE Civil Code, applies relevant articles of the law to make a decision, and ensures compliance with legal standards and rules within the UAE jurisdiction.
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <Button variant="ghost" onClick={onClose4}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            </Grid>
          </Stack>
        </Flex>
      </Box>
      </Box>
      </Box>
      </LightMode>
    // </ChakraProvider>
  )
}
