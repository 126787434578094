import { useState, useEffect, ChangeEvent, useCallback } from "react";
import {
  ChakraProvider,
  theme,
  Box,
  VStack,
  Button,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Text,
  Spinner,
  List,
  ListItem,
  Divider,
  Badge,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import { useUserAuth } from "../context/userAuthContext";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  Timestamp,
  getDoc,
  updateDoc,
} from "firebase/firestore";

type Invitation = {
  id: string;
  inviter: string;
  invitee: string;
  status: "pending" | "accepted" | "rejected";
  timestamp: Timestamp;
};


export const DisputeInvitationPage: React.FC = () => {
  const { user } = useUserAuth();

  const [searchInput, setSearchInput] = useState<string>("");
  const [sentInvitations, setSentInvitations] = useState<any[]>([]);
  const [receivedInvitations, setReceivedInvitations] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const toast = useToast();



  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  // const fetchInvitations = async () => {
  //   if (!user) return;
  //   const userEmail = user.email || "";

  //   try {
  //     // Query for sent invitations
  //     const sentQuery = query(collection(db, "invitations"), where("inviter", "==", userEmail));
  //     const sentSnapshot = await getDocs(sentQuery);
  //     const fetchedSentInvitations = sentSnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     setSentInvitations(fetchedSentInvitations);

  //     // Query for received invitations
  //     const receivedQuery = query(
  //       collection(db, "invitations"),
  //       where("invitee", "==", userEmail)
  //     );
  //     const receivedSnapshot = await getDocs(receivedQuery);
  //     const fetchedReceivedInvitations = receivedSnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     setReceivedInvitations(fetchedReceivedInvitations);
  //   } catch (error) {
  //     console.error("Error fetching invitations: ", error);
  //     toast({
  //       title: "Error.",
  //       description: "Failed to fetch invitations.",
  //       status: "error",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchInvitations();
  // }, [user]);

  // Memoized function to fetch invitations
  const fetchInvitations = useCallback(async () => {
    if (!user) return;
    const userEmail = user.email || "";

    try {
      // Query for sent invitations
      const sentQuery = query(collection(db, "invitations"), where("inviter", "==", userEmail));
      const sentSnapshot = await getDocs(sentQuery);
      const fetchedSentInvitations = sentSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Invitation[];
      setSentInvitations(fetchedSentInvitations);

      // Query for received invitations
      const receivedQuery = query(
        collection(db, "invitations"),
        where("invitee", "==", userEmail)
      );
      const receivedSnapshot = await getDocs(receivedQuery);
      const fetchedReceivedInvitations = receivedSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Invitation[];
      setReceivedInvitations(fetchedReceivedInvitations);
    } catch (error) {
      console.error("Error fetching invitations: ", error);
      toast({
        title: "Error.",
        description: "Failed to fetch invitations.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [user, toast]);

  // Fetch invitations when the component mounts or when `user` changes
  useEffect(() => {
    fetchInvitations();
  }, [fetchInvitations]);

  const handleInvite = async () => {
    if (!user) return;
    const inviterEmail = user.email || "";
    const inviteeEmail = searchInput;

    if (inviterEmail === inviteeEmail) {
      toast({
        title: "Invalid Invitation.",
        description: "You cannot invite yourself.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const invitationRef = doc(db, "invitations", `${inviterEmail}_${inviteeEmail}`);
      const invitationDoc = await getDoc(invitationRef);

      if (invitationDoc.exists()) {
        toast({
          title: "Invitation Pending.",
          description: `An invitation is already pending for ${inviteeEmail}.`,
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await setDoc(invitationRef, {
          inviter: inviterEmail,
          invitee: inviteeEmail,
          status: "pending",
          timestamp: Timestamp.now(),
        });

        toast({
          title: "Invitation Sent.",
          description: `Invitation sent to ${inviteeEmail}.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        fetchInvitations(); // Refresh invitations
      }
    } catch (error) {
      console.error("Error sending invitation: ", error);
      toast({
        title: "Error.",
        description: "Failed to send the invitation.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAcceptInvitation = async (invitationId: string) => {
    try {
      const invitationRef = doc(db, "invitations", invitationId);
      await updateDoc(invitationRef, { status: "accepted" });

      toast({
        title: "Invitation Accepted.",
        description: "You have accepted the invitation.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      fetchInvitations(); // Refresh invitations
    } catch (error) {
      console.error("Error accepting invitation: ", error);
      toast({
        title: "Error.",
        description: "Failed to accept the invitation.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Box p={4}>
        <VStack spacing={8} align="center" mt="10vh">
          <Heading as="h1" size="xl" textAlign="center">
            Invite a User to Dispute
          </Heading>

          <InputGroup size="lg" w="50%">
            <Input
              placeholder="Enter user email..."
              value={searchInput}
              onChange={handleSearchChange}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleInvite} colorScheme="teal">
                Invite
              </Button>
            </InputRightElement>
          </InputGroup>

          <Heading as="h2" size="lg" textAlign="center" mt={10}>
            Sent Invitations
          </Heading>
          {loading ? (
            <Spinner />
          ) : (
            <List spacing={4} w="50%">
              {sentInvitations.length > 0 ? (
                sentInvitations.map((invitation) => (
                  <ListItem key={invitation.id}>
                    <Box p={4} borderWidth="1px" borderRadius="md" shadow="sm">
                      <Text><b>Invitee:</b> {invitation.invitee}</Text>
                      <Text>
                        <b>Status:</b>{" "}
                        <Badge colorScheme={invitation.status === "pending" ? "yellow" : "green"}>
                          {invitation.status}
                        </Badge>
                      </Text>
                      <Text><b>Sent On:</b> {invitation.timestamp.toDate().toLocaleString()}</Text>
                    </Box>
                    <Divider />
                  </ListItem>
                ))
              ) : (
                <Text>No invitations sent yet.</Text>
              )}
            </List>
          )}

          <Heading as="h2" size="lg" textAlign="center" mt={10}>
            Received Invitations
          </Heading>
          {loading ? (
            <Spinner />
          ) : (
            <List spacing={4} w="50%">
              {receivedInvitations.length > 0 ? (
                receivedInvitations.map((invitation) => (
                  <ListItem key={invitation.id}>
                    <Box p={4} borderWidth="1px" borderRadius="md" shadow="sm">
                      <Text><b>Inviter:</b> {invitation.inviter}</Text>
                      <Text>
                        <b>Status:</b>{" "}
                        <Badge colorScheme={invitation.status === "pending" ? "yellow" : "green"}>
                          {invitation.status}
                        </Badge>
                      </Text>
                      <Button
                        mt={2}
                        colorScheme="teal"
                        size="sm"
                        onClick={() => handleAcceptInvitation(invitation.id)}
                        isDisabled={invitation.status !== "pending"}
                      >
                        Accept
                      </Button>
                    </Box>
                    <Divider />
                  </ListItem>
                ))
              ) : (
                <Text>No invitations received yet.</Text>
              )}
            </List>
          )}
        </VStack>
      </Box>
    </ChakraProvider>
  );
};

export default DisputeInvitationPage;